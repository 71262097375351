.wrapper
{
    display: flex;
    width: 100%;
}

.left
{
    flex: 0 0 90%;
    padding-right: 5px;
}

.right
{
    display: flex;
}
.progress-table
{
    width: 100%;
}