.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.firstHeader
{
  padding-top: 50px;
}

.madeByText 
{
  padding: 0.6em;
}

.user-type
{
  color: blue;
  font-weight:bold;
}

.uon-image
{
  height: 200px;
}

.chart-wrapper
{
  padding-left: 20%;
  padding-right: 20%;
}

@media only screen and (max-width: 500px) {
  .chart-wrapper
  {
    padding-left: 1%;
    padding-right: 1%;
  }
}

.button
{
    background-color: #000000;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition: 0.2s;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 190px;
}

.made-by {
  position: fixed;
  bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

@media only screen and (max-height: 800px) {
  .made-by {
    position: relative;
  }
}